/*Package Imports*/
import React, { useEffect, useState, useCallback } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
/*App Imports*/
import { memberActions } from "../../../data/redux/store/member";
import { themeActions } from "../../../data/redux/store/theme";
import { authActions } from "../../../data/redux/store/auth";
import { useGetMemberByMemberEmailQuery, usePostOpenMemberSessionMutation, useGetAlertsByMemberIDQuery, useGetAutoReportsByMemberIDQuery, useGetIsFirstLoginQuery, useGetDismissedAlertsListQuery } from "../../../data/redux/store/backofficeApi";
import OrganizationData from "./organizationData";

//Retrieve current member's data based on email
const MemberData = () => {
  const dispatch = useDispatch(); //current member state: set all values, theme state: set current theme based on member, auth state: set member session id
  const currentMemberEmail = useSelector((state) => state.auth.email);
  const [loading, setLoading] = useState(true);

  //endpoint api/member?memberEmail={memberEmail}
  const { data: getMember, error: errorGetMember } = useGetMemberByMemberEmailQuery(currentMemberEmail);
  const { data: getNotifications, error: errorGetNotifications } = useGetAlertsByMemberIDQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
  const { data: getAutoReports, error: errorGetAutoReports } = useGetAutoReportsByMemberIDQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
  const { data: getIsFirstLogin } = useGetIsFirstLoginQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
  const { data: getDismissedErrorsAlertsList, error: errorGetDismissedErrorsAlertsList } = useGetDismissedAlertsListQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });

  //endpoint api/member/openmembersession
  const [openMemberSession] = usePostOpenMemberSessionMutation();

  const getCurrentMember = useCallback(async () => {
    if (getMember !== undefined && getNotifications !== undefined && getAutoReports !== undefined && getDismissedErrorsAlertsList !== undefined && getIsFirstLogin !== undefined) {
      dispatch(memberActions.setMember(getMember));
      dispatch(memberActions.setValue({ field: "notifications", value: getNotifications }));
      dispatch(memberActions.setValue({ field: "autoReports", value: getAutoReports }));
      dispatch(memberActions.setValue({ field: "isFirstLogin", value: getIsFirstLogin }));
      dispatch(memberActions.setValue({ field: "dismissedList", value: getDismissedErrorsAlertsList }));

      if (getMember.theme === 1) {
        if (localStorage.getItem("rememberMe") === "true") {
          localStorage.setItem("theme", "lightMode");
        }
        dispatch(memberActions.setValue({ field: "defaultTheme", value: "lightMode" }));
        dispatch(themeActions.toggleColorMode("lightMode"));
      } else if (getMember.theme === 2) {
        if (localStorage.getItem("rememberMe") === "true") {
          localStorage.setItem("theme", "darkMode");
        }
        dispatch(memberActions.setValue({ field: "defaultTheme", value: "darkMode" }));
        dispatch(themeActions.toggleColorMode("darkMode"));
      }

      let userAgent = navigator.userAgent;
      let browserName;

      // Try to get the IP address from this website. If it fails, just call the ip address blocked;
      let requestGeolocationSuccess = true;
      var ip;
      const res = await axios.get("https://geolocation-db.com/json/").catch( function (error) { console.log('error getting IP'); requestGeolocationSuccess = false;});
      
      if (requestGeolocationSuccess == true) {
        ip = res.data.IPv4;
      } else {
        ip = 'blocked';
      }
      
      if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = "Chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = "Firefox";
      } else if (userAgent.match(/safari/i)) {
        browserName = "Safari";
      } else if (userAgent.match(/opr\//i)) {
        browserName = "Opera";
      } else if (userAgent.match(/edg/i)) {
        browserName = "Edge";
      } else {
        browserName = "Other";
      }

      const sessionbody = {
        MemberID: getMember.memberID,
        Browser: browserName,
        IP: ip,
      };
      openMemberSession({ body: sessionbody })
        .unwrap()
        .then((fulfilled) => {
          setLoading(false);
          dispatch(authActions.setMemberSessionID(fulfilled));
        })
        .catch((rejected) => {
          console.log(rejected);
          setLoading(false);
        });
    } else if (errorGetMember !== undefined) {
      console.log(errorGetMember);
    }
  }, [dispatch, errorGetMember, getMember, openMemberSession, getNotifications, getAutoReports, getIsFirstLogin, getDismissedErrorsAlertsList]);

  useEffect(() => {
    getCurrentMember();
  }, [getCurrentMember]);

  //if an error occured getting member and data is done loading, redirect to 500 page, else if we got back data and it is not loading, get organization data
  return (
    <div>
      {(errorGetMember !== undefined || errorGetNotifications !== undefined || errorGetAutoReports !== undefined) && !loading ? (
        <Redirect to="/auth/500" />
      ) : getMember !== undefined && getNotifications !== undefined && getAutoReports !== undefined && getDismissedErrorsAlertsList !== undefined && !loading ? (
        <OrganizationData />
      ) : (
        ""
      )}
    </div>
  );
};

export default MemberData;

// BO_83 this was used for old version of backoffice, might need this later. will delete once fully works with both plus and cloud 
/*Package Imports*/
// import React, { useEffect, useState, useCallback } from "react";
// import { Redirect } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios";
// /*App Imports*/
// import { memberActions } from "../../../data/redux/store/member";
// import { themeActions } from "../../../data/redux/store/theme";
// import { authActions } from "../../../data/redux/store/auth";
// import { useGetMemberByMemberEmailQuery, usePostOpenMemberSessionMutation, useGetAlertsByMemberIDQuery, useGetAutoReportsByMemberIDQuery, useGetIsFirstLoginQuery, useGetDismissedAlertsListQuery } from "../../../data/redux/store/backofficeApi";
// import OrganizationData from "./organizationData";

// //Retrieve current member's data based on email
// const MemberData = () => {
//   const dispatch = useDispatch(); //current member state: set all values, theme state: set current theme based on member, auth state: set member session id
//   const currentMemberEmail = useSelector((state) => state.auth.email);
//   const [loading, setLoading] = useState(true);
//   //endpoint api/member?memberEmail={memberEmail}
//   const { data: getMember, error: errorGetMember } = useGetMemberByMemberEmailQuery(currentMemberEmail);
//   const { data: getNotifications, error: errorGetNotifications } = useGetAlertsByMemberIDQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
//   const { data: getAutoReports, error: errorGetAutoReports } = useGetAutoReportsByMemberIDQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
//   const { data: getIsFirstLogin } = useGetIsFirstLoginQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
//   const { data: getDismissedErrorsAlertsList, error: errorGetDismissedErrorsAlertsList } = useGetDismissedAlertsListQuery(getMember === undefined ? 0 : getMember.memberID, { skip: getMember === undefined });
//   //endpoint api/member/openmembersession
//   const [openMemberSession] = usePostOpenMemberSessionMutation();

//   const getCurrentMember = useCallback(async () => {
//     if (getMember !== undefined && getNotifications !== undefined && getAutoReports !== undefined && getDismissedErrorsAlertsList !== undefined && getIsFirstLogin !== undefined) {
//       dispatch(memberActions.setMember(getMember));
//       dispatch(memberActions.setValue({ field: "notifications", value: getNotifications }));
//       dispatch(memberActions.setValue({ field: "autoReports", value: getAutoReports }));
//       dispatch(memberActions.setValue({ field: "isFirstLogin", value: getIsFirstLogin }));
//       dispatch(memberActions.setValue({ field: "dismissedList", value: getDismissedErrorsAlertsList }));

//       if (getMember.theme === 1) {
//         if (localStorage.getItem("rememberMe") === "true") {
//           localStorage.setItem("theme", "lightMode");
//         }
//         dispatch(memberActions.setValue({ field: "defaultTheme", value: "lightMode" }));
//         dispatch(themeActions.toggleColorMode("lightMode"));
//       } else if (getMember.theme === 2) {
//         if (localStorage.getItem("rememberMe") === "true") {
//           localStorage.setItem("theme", "darkMode");
//         }
//         dispatch(memberActions.setValue({ field: "defaultTheme", value: "darkMode" }));
//         dispatch(themeActions.toggleColorMode("darkMode"));
//       }

//       let userAgent = navigator.userAgent;
//       let browserName;

//       // Try to get the IP address from this website. If it fails, just call the ip address blocked;
//       let requestGeolocationSuccess = true;
//       var ip;
//       const res = await axios.get("https://geolocation-db.com/json/").catch( function (error) { console.log('error getting IP'); requestGeolocationSuccess = false;});
      
//       if (requestGeolocationSuccess == true) {
//         ip = res.data.IPv4;
//       } else {
//         ip = 'blocked';
//       }
      
//       if (userAgent.match(/chrome|chromium|crios/i)) {
//         browserName = "Chrome";
//       } else if (userAgent.match(/firefox|fxios/i)) {
//         browserName = "Firefox";
//       } else if (userAgent.match(/safari/i)) {
//         browserName = "Safari";
//       } else if (userAgent.match(/opr\//i)) {
//         browserName = "Opera";
//       } else if (userAgent.match(/edg/i)) {
//         browserName = "Edge";
//       } else {
//         browserName = "Other";
//       }

//       const sessionbody = {
//         MemberID: getMember.memberID,
//         Browser: browserName,
//         IP: ip,
//       };
//       openMemberSession({ body: sessionbody })
//         .unwrap()
//         .then((fulfilled) => {
//           setLoading(false);
//           dispatch(authActions.setMemberSessionID(fulfilled));
//         })
//         .catch((rejected) => {
//           console.log(rejected);
//           setLoading(false);
//         });
//     } else if (errorGetMember !== undefined) {
//       console.log(errorGetMember);
//     }
//   }, [dispatch, errorGetMember, getMember, openMemberSession, getNotifications, getAutoReports, getIsFirstLogin, getDismissedErrorsAlertsList]);

//   useEffect(() => {
//     getCurrentMember();
//   }, [getCurrentMember]);

//   //if an error occured getting member and data is done loading, redirect to 500 page, else if we got back data and it is not loading, get organization data
//   return (
//     <div>
//       {(errorGetMember !== undefined || errorGetNotifications !== undefined || errorGetAutoReports !== undefined) && !loading ? (
//         <Redirect to="/auth/500" />
//       ) : getMember !== undefined && getNotifications !== undefined && getAutoReports !== undefined && getDismissedErrorsAlertsList !== undefined && !loading ? (
//         <OrganizationData />
//       ) : (
//         ""
//       )}
//     </div>
//   );
// };

// export default MemberData;
